<template>
  <div>
    <b-modal
      @hidden="$emit('hidden')"
      ref="other-labels-modal"
      body-class="p-0"
      size="xmd"
      title="Other Labels"
      title-tag="h2"
      hide-footer
    >
      <div class="d-flex justify-content-end w-100 py-2 pr-2">
        <b-button variant="success" @click="openOtherLabelForm()">
          <feather-icon icon="PlusIcon" />
          Add
        </b-button>
      </div>
      <b-card no-body class="mb-0">
        <OtherLabelTable
          :key="tableKey"
          @onEditOtherLabel="onEditOtherLabel"
          @onRowClicked="onRowClicked"
        />
      </b-card>
    </b-modal>
    <other-label-form
      v-if="isOtherLabelFormVisible"
      :labelId="selectedLabelId"
      :labelName="selectedLabelName"
      @hidden="closeOtherLabelForm"
      @onSaveOtherLabel="onSaveOtherLabel"
    />
  </div>
</template>
<script>
// Import Service
import OtherLabelTable from "@/views/commons/components/reports/tabs/debtor-clients/components/other-labels/OtherLabelTable.vue";
import OtherLabelForm from "@/views/commons/components/reports/tabs/debtor-clients/components/other-labels/OtherLabelForm.vue";
export default {
  components: {
    OtherLabelTable,
    OtherLabelForm,
  },
  mounted() {
    this.toggleModal("other-labels-modal");
  },
  data() {
    return {
      isOtherLabelFormVisible: false,
      tableKey: 0,
      selectedLabelId: null,
      selectedLabelName: null,
    };
  },
  methods: {
    closeOtherLabelForm() {
      this.isOtherLabelFormVisible = false;
    },
    openOtherLabelForm() {
      (this.selectedLabelId = null),
        (this.selectedLabelName = null),
        (this.isOtherLabelFormVisible = true);
    },
    onSaveOtherLabel() {
      this.closeOtherLabelForm();
      this.tableKey++;
    },
    onEditOtherLabel(labelId, labelName) {
      this.selectedLabelId = labelId;
      this.selectedLabelName = labelName;
      this.isOtherLabelFormVisible = true;
    },
    onRowClicked(row) {
      this.$emit("onRowClicked", row);
    },
  },
};
</script>