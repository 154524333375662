<template>
  <div>
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="updateTable"
    >
      <template #table>
        <b-table
          ref="refOtherLabelTable"
          class="position-relative font-small-3"
          primary-key="id"
          empty-text="No matching records found"
          responsive="sm"
          sticky-header="68vh"
          no-border-collapse
          no-provider-filtering
          show-empty
          :fields="fields"
          :items="getOtherLabels"
          :busy.sync="isBusy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(actions)="data">
            <feather-icon
              icon="CheckIcon"
              class="cursor-pointer text-success mr-1"
              @click="onRowClicked(data.item)"
            />
            <feather-icon
              icon="Edit2Icon"
              class="cursor-pointer text-warning mr-1"
              @click="$emit('onEditOtherLabel', data.item.id, data.item.name)"
            />
            <feather-icon
              @click="deleteOtherLabel(data.item.id)"
              icon="TrashIcon"
              class="cursor-pointer text-danger"
            />
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import DebtorClientsService from "@/views/commons/components/reports/tabs/debtor-clients/service/debtor-clients.service.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search...",
        model: "",
      },
      isBusy: false,
      fields: [
        {
          key: "name",
          label: "Description",
        },
        {
          key: "created_at",
          label: "Created At",
          sortable: true,
          formatter: (value) => {
            return this.$options.filters.myGlobal(value);
          },
        },
        {
          key: "created_by",
          label: "Created By",
        },
        {
          key: "actions",
          label: "Actions",
          sortable: false,
          thStyle: { width: "10%" },
        },
      ],
      items: [],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      sortDesc: true,
      others: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    updateTable() {
      this.$refs.refOtherLabelTable.refresh();
    },
    onRowClicked(row) {
      this.$emit("onRowClicked", row);
    },
    async getOtherLabels(ctx) {
      try {
        const params = {
          order_by: ctx.sortBy === "" ? "ol.created_at" : ctx.sortBy,
          order: ctx.sortDesc ? "desc" : "asc",
          perPage: ctx.perPage,
          page: ctx.currentPage,
          searchText: this.filterPrincipal.model,
        };
        const {
          data: others,
          current_page,
          from,
          per_page,
          last_page,
          total,
          to,
        } = await DebtorClientsService.getOtherLabels(params);
        this.others = others;
        this.paginate.currentPage = current_page;
        this.startPage = from;
        this.paginate.perPage = per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = last_page;
        this.totalRows = total;
        this.toPage = to;
        return this.others || [];
      } catch (e) {
        console.log(e);
      }
      return [];
    },
    async deleteOtherLabel(id) {
      try {
        const { isConfirmed } = await this.showConfirmSwal(
          "Are you sure you want to delete this other label?",
          null
        );
        if (!isConfirmed) return;
        this.addPreloader();
        await DebtorClientsService.deleteOtherLabel({
          id,
          deleted_by: this.currentUser.user_id,
        });
        this.$refs.refOtherLabelTable.refresh();
      } catch (e) {
        console.log(e);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
</style>