import { amgApi } from "@/service/axios"

class DebtorClientsService {
  async getCounterDebtorClients(params) {
    // const params = {
    //     month: date.substring(5),
    //     year:date.substring(0,4),
    //     user_id:userId
    // }

    try {
      const { data } = await amgApi.post(
        "/credit-expert/debtors-report/clients",
        params
      )
      return data
    } catch (error) {
      console.error(error)
      return []
    }
  }
  async getDebtorsDetail(params) {
    try {
      const { data } = await amgApi.post(
        "/credit-expert/debtors-report/clients-detail",
        params
      )

      return data
    } catch (error) {
      console.error(error)
      return []
    }
  }
  async getResultsClient() {
    try {
      const { data } = await amgApi.post(
        "/credit-expert/debtors-report/get-results-client"
      )
      return data
    } catch (error) {
      console.error(error)
      return []
    }
  }
  async getLabelsClient() {
    try {
      const { data } = await amgApi.post(
        "/credit-expert/debtors-report/get-labels-client"
      )
      return data
    } catch (error) {
      console.error(error)
      return []
    }
  }
  async registerResultClientAccount(params) {
    const { client_account_id, transaction_id, result_id, status, user_id } =
      params

    try {
      const { data } = await amgApi.post(
        "/credit-expert/debtors-report/register-result-client-account",
        {
          client_account_id,
          transaction_id,
          result_id,
          status,
          user_id,
        }
      )
      return data.msg
    } catch (error) {
      return error
    }
  }
  async registerLabelClientAccount(params) {
    const {
      type,
      client_account_id,
      transaction_id,
      label_id,
      status,
      user_id,
      observation,
      otherValue,
      remember,
    } = params

    try {
      const { data } = await amgApi.post(
        "/credit-expert/debtors-report/register-label-client-account",
        {
          type,
          client_account_id,
          transaction_id,
          label_id,
          status,
          user_id,
          observation,
          otherValue,
          remember,
        }
      )
      return data.msg
    } catch (error) {
      return error
    }
  }

  async getOtherLabels(params) {
    try {
      const { data } = await amgApi.post(
        `/credit-expert/debtors-report/get-other-labels?page=${params.page}`,
        params
      )
      return data
    } catch (error) {
      return error
    }
  }
  async createOtherLabel(params) {
    try {
      const { data } = await amgApi.post(
        `/credit-expert/debtors-report/create-other-label`,
        params
      )
      return data
    } catch (error) {
      return error
    }
  }
  async editOtherLabel(params) {
    try {
      const { data } = await amgApi.post(
        `/credit-expert/debtors-report/edit-other-label`,
        params
      )
      return data
    } catch (error) {
      return error
    }
  }
  async deleteOtherLabel(params) {
    try {
      const { data } = await amgApi.post(
        `/credit-expert/debtors-report/delete-other-label`,
        params
      )
      return data
    } catch (error) {
      return error
    }
  }
  async labelTracking(client_account_id) {
    try {
      const { data } = await amgApi.post(
        "/credit-expert/debtors-report/label-tracking",
        {
          client_account_id,
        }
      )
      return data
    } catch (error) {
      return error
    }
  }
  async resultTracking(transaction_id) {
    try {
      const { data } = await amgApi.post(
        "/credit-expert/debtors-report/result-tracking",
        {
          transaction_id,
        }
      )
      return data
    } catch (error) {
      return error
    }
  }
}

export default new DebtorClientsService()
