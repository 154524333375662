<template>
  <b-col class="flex-nowrap">
    <b-row
      :cols="
        (isPending && isSupervisorOrCeoOrChief) ||
        ((isAdvisor || isAssistantOrAgent || isSupervisor || isChief) &&
          hasNotLabel &&
          hasOptionSelected &&
          selected === 6 &&
          otherValue !== '')
          ? 8
          : 12
      "
      class="d-flex justify-content-center p-0 m-0"
    >
      <b-form-input
        size="sm"
        v-model="other_label"
        @click="activateSelect"
        readonly
        style="cursor: pointer"
        v-if="
          selected == 6 &&
          other_label != null &&
          isDecline == false &&
          (isPending || isApprove)
        "
      />
      <template>
        <b-form-select
          v-if="!notHasTransactionId && selected != 6"
          ref="selectLabel1"
          size="sm"
          class="select-icon-none input-background-white"
          v-model="selected"
          :options="options"
          @input="selectOption"
        />
        <b-form-select
          v-if="notHasTransactionId"
          ref="selectLabel2"
          size="sm"
          title="This client does not have payments yet!"
          class="select-icon-none input-background-white border-info"
          v-model="selected"
          :options="options"
          @input="selectOption"
          disabled
        />
      </template>
    </b-row>
    <b-row>

      <b-col
        v-if="label_other_save  &&
          !hasOptionSelected"
        class="d-flex justify-content-around"
        style="margin-top: 0.8rem"
      >
        <feather-icon
          icon="CornerUpLeftIcon"
          @click="recover"
          class="text-secondary cursor-pointer"
          size="20"
        />
      </b-col>
      <b-col
        v-if="isPending && isSupervisorOrCeoOrChief && !sendApprove"
        class="d-flex justify-content-around"
        style="margin-top: 0.8rem"
      >
        <feather-icon
          icon="ThumbsUpIcon"
          @click="approve"
          class="text-primary cursor-pointer"
          size="20"
        />
        <feather-icon
          icon="ThumbsDownIcon"
          @click="decline"
          class="text-danger cursor-pointer"
          size="20"
        />
      </b-col>
      <b-col
        v-else-if="
          (isAdvisor || isAssistantOrAgent || isSupervisorOrCeoOrChief) &&
          hasNotLabel &&
          hasOptionSelected &&
          sendSaveselection == false
        "
        class="d-flex justify-content-around"
        style="margin-top: 0.8rem"
      >
        <b-icon
          icon="check-circle"
          @click="saveSelection"
          class="text-success cursor-pointer"
          scale="1.5"
        />
        <b-icon
          icon="x-circle"
          @click="deleteSelection"
          class="text-danger cursor-pointer"
          scale="1.5"
        />
      </b-col>
    </b-row>

    <other-label-main
      v-if="showModal"
      @hidden="resetOther"
      @onRowClicked="saveOther"
    />
  </b-col>
</template>
<script>
// Import Service
// Import Components
import Swal from "sweetalert2";
// Import Vuex
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import DebtorClientsService from "@/views/commons/components/reports/tabs/debtor-clients/service/debtor-clients.service.js";
import OtherLabelMain from "@/views/commons/components/reports/tabs/debtor-clients/components/other-labels/OtherLabelMain.vue";
export default {
  components: {
    vSelect,
    OtherLabelMain,
  },
  props: {
    client_account_id: {
      type: String,
      required: true,
    },
    transaction_id: {
      type: String,
    },
    status: {
      default: null,
    },
    label_id: {
      default: null,
    },
    other_label: {
      default: null,
    },
    labels: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      selected: null,
      options: [],
      showModal: false,
      otherValue: "",
      others: [],
      other: null,
      remember: false,
      sendSaveselection: false,
      sendApprove: false,
      hasChangedOption: false,
      showOtherLabel: false,
      label_other_save: false,
    };
  },
  created() {
    if (this.isDecline == true) {
      this.selected = null;
    } else {
      this.selected = this.label_id;
    }

  },
  mounted() {
    this.options = [
      { value: null, text: "Select a label", disabled: true },
      ...this.labels,
    ];

  },
  methods: {
    activateSelect() {
      this.$emit("changeOther");
      this.selected = null;
      this.label_other_save = true;
    },
    recover() {
      this.label_other_save = false;
      this.selected = 6;
      this.hasChangedOption=false;
    },

    async saveOther(row) {
      if (row.name) {
        this.otherValue = row.name;
        this.selected = 6;
        this.remember = true;
        await this.saveSelection();
      }
    },
    resetOther() {
      this.other = null;
      this.remember = false;
      this.showModal = false;
      this.selected = null;
      if(this.other_label!=null){
        this.label_other_save = true;
      }
    },
    selectOption(value) {
      if (value === 6) {
        this.showModal = true;
      } else {
        this.selected = value;
        // this.label_other_save = false;
      }
      if (this.label_id != value) {
        this.hasChangedOption = true;
      } else {
        this.hasChangedOption = false;
      }
      this.sendSaveselection = false;
    },
    async getOtherLabels() {
      const others = await DebtorClientsService.getOtherLabels();
      others.forEach((element) => {
        this.others.push(element.name);
      });
    },
    async saveSelection() {
      const params = {
        type: 1,
        client_account_id: this.client_account_id,
        transaction_id: this.transaction_id,
        label_id: this.selected,
        status: 1,
        user_id: this.currentUser.user_id,
        observation: null,
        otherValue: this.otherValue,
        remember: this.remember,
      };
      if (this.isSupervisorOrCeoOrChief) {
        const { value } = await this.showConfirmSwal(
          "Are you sure that you want to select and approve it?"
        );
        if (value) {
          try {
            const msg = await DebtorClientsService.registerLabelClientAccount(
              params
            );
            try {
              await this.approve_automatic();
            } catch (error) {
              this.showErrorSwal(error);
            }
            if (msg.errorInfo) {
              this.showErrorSwal(msg.errorInfo[2]);
            }
            if (this.otherValue != "") {
              this.$emit("onOtherLabel", this.otherValue, 2);
            }
            this.sendSaveselection = true;
            this.remember = false;
            this.showModal = false;
            if (this.selected) {
              this.$emit("onSaveSelection", this.item, this.selected, 2);
            }
            this.label_other_save = false;
          } catch (error) {
            this.showErrorSwal(error);
          }
          
        }
      } else {
        const { value } = await this.showConfirmSwal(
          "Are you sure that you want to select it?"
        );
        if (value) {
          try {
            const msg = await DebtorClientsService.registerLabelClientAccount(
              params
            );
            if (this.otherValue != "") {
              this.$emit("onOtherLabel", this.otherValue, 1);
            }
            this.sendSaveselection = true;
            this.remember = false;
            this.showModal = false;
            if (this.selected) {
              this.$emit("onSaveSelection", this.item, this.selected, 1);
            }
            this.showSuccessSwal("Selected");
            this.label_other_save = false;
          } catch (error) {
            this.showErrorSwal(error);
          }
        }
      }
      
    },
    deleteSelection() {
      this.selected = null;
    },
    async approve() {
      const params = {
        type: 2,
        client_account_id: this.client_account_id,
        transaction_id: this.transaction_id,
        label_id: this.selected,
        status: 2,
        user_id: this.currentUser.user_id,
        observation: null,
      };
      const { value } = await this.showConfirmSwal(
        "Are you sure that you want to approve it?"
      );
      if (value) {
        try {
          const msg = await DebtorClientsService.registerLabelClientAccount(
            params
          );
          // this.$emit("refreshTable");
          this.sendApprove = true;
          if (msg.errorInfo) this.showErrorSwal(msg.errorInfo[2]);
          else this.showSuccessSwal("Approved");
        } catch (error) {
          console.error(error.message);
          throw new Error(error);
        }
      }
    },
    async approve_automatic() {
      const params = {
        type: 2,
        client_account_id: this.client_account_id,
        transaction_id: this.transaction_id,
        label_id: this.selected,
        status: 2,
        user_id: this.currentUser.user_id,
        observation: null,
      };

      try {
        const msg = await DebtorClientsService.registerLabelClientAccount(
          params
        );
        // this.$emit("refreshTable");
        this.sendApprove = true;
        if (msg.errorInfo) this.showErrorSwal(msg.errorInfo[2]);
        else this.showSuccessSwal("Selected and approved");
      } catch (error) {
        console.error(error.message);
        throw new Error(error);
      }
    },
    async decline() {
      this.label_other_save = false;
      const params = {
        type: 2,
        client_account_id: this.client_account_id,
        transaction_id: this.transaction_id,
        label_id: this.selected,
        status: 3,
        user_id: this.currentUser.user_id,
        observation: "",
      };
      const config = {
        preConfirm: (obs) => {
          if (!obs.length) {
            Swal.showValidationMessage("Has not observation");
          } else {
            params.observation = obs;
          }
          return;
        },
      };
      // const  {value} = await this.showConfirmSwal(
      //     "If are you sure than you want decline this result, insert a observation.",
      //     "You won't be able to revert this!",
      //     config);

      const { value } = await Swal.fire({
        title: "Confirm",
        text: "If you are sure that you want decline this result, insert a observation.",
        showCancelButton: true,
        input: "text",
        confirmButtonText: "Yes, disapprove",
        preConfirm: (obs) => {
          if (!obs.length) {
            Swal.showValidationMessage("Has not observation");
          } else {
            params.observation = obs;
          }
          return;
        },
      });

      if (value) {
        try {
          const msg = await DebtorClientsService.registerLabelClientAccount(
            params
          );
          // this.$emit("refreshTable");
          this.selected = null;
          // this.other_label = null;
          this.$emit("onOtherLabel", null, 3);
          if (msg.errorInfo) this.showErrorSwal(msg.errorInfo[2]);
          else {
            this.showSuccessSwal(msg);
            this.sendApprove = true;
          }
        } catch (error) {
          console.error(error.message);
          throw new Error(error);
        }
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    hasOptionSelected() {
      return !!this.selected;
    },
    hasStatus() {
      return !!this.status;
    },
    hasNotLabel() {
      return this.status == null || this.hasChangedOption;
    },
    isPending() {
      return this.status == 1;
    },
    isApprove() {
      return this.status == 2;
    },
    isDecline() {
      return this.status == 3;
    },
    isSupervisorOrCeoOrChief() {
      return (
        this.currentUser.role_id == 1 ||
        this.currentUser.role_id == 2 ||
        this.currentUser.role_id == 17
      );
    },
    isAdvisor() {
      return this.currentUser.role_id == 3;
    },
    labelName() {
      return this.labels.find((label) => label.value == this.selected)?.text;
    },
    isAssistantOrAgent() {
      if (this.currentUser.role_id == 14 || this.currentUser.role_id == 15) {
        return true;
      }
    },
    notHasTransactionId() {
      return this.transaction_id == null;
    },
  },
  watch: {
    labels() {
      this.options = [
        { value: null, text: "Select a label", disabled: true },
        ...this.labels,
      ];
    },
  },

};
</script>