var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-col',{staticClass:"flex-nowrap"},[_c('b-row',{staticClass:"d-flex justify-content-center p-0 m-0",attrs:{"cols":(_vm.isPending && _vm.isSupervisorOrCeoOrChief) ||
      ((_vm.isAdvisor || _vm.isAssistantOrAgent || _vm.isSupervisor || _vm.isChief) &&
        _vm.hasNotLabel &&
        _vm.hasOptionSelected &&
        _vm.selected === 6 &&
        _vm.otherValue !== '')
        ? 8
        : 12}},[(
        _vm.selected == 6 &&
        _vm.other_label != null &&
        _vm.isDecline == false &&
        (_vm.isPending || _vm.isApprove)
      )?_c('b-form-input',{staticStyle:{"cursor":"pointer"},attrs:{"size":"sm","readonly":""},on:{"click":_vm.activateSelect},model:{value:(_vm.other_label),callback:function ($$v) {_vm.other_label=$$v},expression:"other_label"}}):_vm._e(),[(!_vm.notHasTransactionId && _vm.selected != 6)?_c('b-form-select',{ref:"selectLabel1",staticClass:"select-icon-none input-background-white",attrs:{"size":"sm","options":_vm.options},on:{"input":_vm.selectOption},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e(),(_vm.notHasTransactionId)?_c('b-form-select',{ref:"selectLabel2",staticClass:"select-icon-none input-background-white border-info",attrs:{"size":"sm","title":"This client does not have payments yet!","options":_vm.options,"disabled":""},on:{"input":_vm.selectOption},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}):_vm._e()]],2),_c('b-row',[(_vm.label_other_save  &&
        !_vm.hasOptionSelected)?_c('b-col',{staticClass:"d-flex justify-content-around",staticStyle:{"margin-top":"0.8rem"}},[_c('feather-icon',{staticClass:"text-secondary cursor-pointer",attrs:{"icon":"CornerUpLeftIcon","size":"20"},on:{"click":_vm.recover}})],1):_vm._e(),(_vm.isPending && _vm.isSupervisorOrCeoOrChief && !_vm.sendApprove)?_c('b-col',{staticClass:"d-flex justify-content-around",staticStyle:{"margin-top":"0.8rem"}},[_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"ThumbsUpIcon","size":"20"},on:{"click":_vm.approve}}),_c('feather-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"ThumbsDownIcon","size":"20"},on:{"click":_vm.decline}})],1):(
        (_vm.isAdvisor || _vm.isAssistantOrAgent || _vm.isSupervisorOrCeoOrChief) &&
        _vm.hasNotLabel &&
        _vm.hasOptionSelected &&
        _vm.sendSaveselection == false
      )?_c('b-col',{staticClass:"d-flex justify-content-around",staticStyle:{"margin-top":"0.8rem"}},[_c('b-icon',{staticClass:"text-success cursor-pointer",attrs:{"icon":"check-circle","scale":"1.5"},on:{"click":_vm.saveSelection}}),_c('b-icon',{staticClass:"text-danger cursor-pointer",attrs:{"icon":"x-circle","scale":"1.5"},on:{"click":_vm.deleteSelection}})],1):_vm._e()],1),(_vm.showModal)?_c('other-label-main',{on:{"hidden":_vm.resetOther,"onRowClicked":_vm.saveOther}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }