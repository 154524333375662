<template>
  <b-modal
    @hidden="$emit('hidden')"
    ref="other-labels-form-modal"
    size="sm"
    :title="labelId ? 'Edit Other Label' : 'Add Other Label'"
    title-tag="h2"
    centered
    ok-title="Save"
    @ok.prevent="!labelId ? createOtherLabel() : editOtherLabel()"
  >
    <b-form-group label="Description">
      <b-form-input
        id="input-other-label"
        v-model="otherLabel"
        placeholder="Enter other label"
      />
    </b-form-group>
  </b-modal>
</template>
<script>
import DebtorClientsService from "@/views/commons/components/reports/tabs/debtor-clients/service/debtor-clients.service.js";
import { mapGetters } from "vuex";
export default {
  props: {
    labelId: {
      type: Number,
    },
    labelName: {
      type: String,
    },
  },
  mounted() {
    if (this.labelId) {
      this.otherLabel = this.labelName;
    }
    this.toggleModal("other-labels-form-modal");
  },
  data() {
    return {
      otherLabel: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async createOtherLabel() {
      try {
        this.addPreloader();
        await DebtorClientsService.createOtherLabel({
          name: this.otherLabel,
          user_id: this.currentUser.user_id,
        });
        this.showSuccessSwal();
        this.$emit("onSaveOtherLabel");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
    async editOtherLabel() {
      try {
        this.addPreloader();
        await DebtorClientsService.editOtherLabel({
          name: this.otherLabel,
          id: this.labelId,
        });
        this.showSuccessSwal();
        this.$emit("onSaveOtherLabel");
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>