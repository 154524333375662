<template>
  <b-col class="flex-nowrap align-items-center">
    <b-row
      :cols="
        isSupervisorOrCeoOrChief && hasSameOptionSelected && hasNotResult
          ? 12
          : 10
      "
      class="d-flex justify-content-center p-0 m-0"
    >
      <b-form-select
        v-if="!notHasTransactionId"
        size="sm"
        v-model="result"
        :options="options"
        @input="selectOption"
        :disabled="isAdvisor || isAssistantOrAgent"
      />
      <b-form-select
        v-else
        title="This client does not have payments yet!"
        class="border-info"
        size="sm"
        v-model="result"
        :options="options"
        @input="selectOption"
        disabled
      />
    </b-row>
    <b-row
      v-if="isSupervisorOrCeoOrChief && !hasSameOptionSelected && !sendStatus"
      class="d-flex justify-content-around"
      style="margin-top: 0.8rem"
    >
      <FeatherIcon
        icon="SaveIcon"
        @click="saveSelection"
        size="20"
        class="text-success cursor-pointer"
      />
      <FeatherIcon
        icon="XCircleIcon"
        @click="deleteSelection"
        class="text-danger cursor-pointer"
        size="20"
      />
    </b-row>
  </b-col>
</template>
<script>
// Import Service
import DebtorClientsService from "../service/debtor-clients.service";
// Import Components
import Swal from "sweetalert2";
// Import Vuex
import { mapGetters } from "vuex";

export default {
  props: {
    client_account_id: {
      type: String,
      required: true,
    },
    transaction_id: {
      type: String,
    },
    status: {
      default: null,
    },
    result_id: {
      default: null,
    },
    results: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.result = this.result_id;

    this.options = [
      { value: null, text: "Select a result", disabled: true },
      ...this.results,
    ];
  },
  data() {
    return {
      result: null,
      options: [],
      sendStatus: false,
    };
  },
  methods: {
    selectOption(value) {
      this.result = value;
      this.sendStatus = false;
    },
    async saveSelection() {
      const params = {
        client_account_id: this.client_account_id,
        transaction_id: this.transaction_id,
        result_id: this.result,
        user_id: this.currentUser.user_id,
      };

      const { value } = await this.showConfirmSwal(
        "Are you sure that you want to save it?"
      );
      if (value) {
        try {
          const msg = await DebtorClientsService.registerResultClientAccount(
            params
          );

          if (msg.errorInfo) {
            this.showErrorSwal(msg.errorInfo[2]);
          } else {
            this.showSuccessSwal("Success!", msg);
            this.sendStatus = true;
            this.$emit("setResult", this.result);
          }
        } catch (error) {
          this.showErrorSwal(error);
          console.log("error");
        }
      }
    },
    deleteSelection() {
      this.result = this.result_id ? this.result_id : null;
    },
    async approve() {
      const params = {
        client_account_id: this.client_account_id,
        result_id: this.result,
        status: 2,
        user_id: this.currentUser.id,
        observation: null,
      };
      const { value } = await swal.fire({
        title: "Confirm",
        text: "Are you sure do you want approve it?",
        showCancelButton: true,
        // buttonsStyling: false,
        confirmButtonText: "Yes, approve",
        type: "question",
        customClass: {
          // confirmButton: "btn-update-sn btn-sm mb-4 py-2",
        },
      });

      if (value) {
        try {
          const msg = await DebtorClientsService.registerResultClientAccount(
            params
          );
          // this.$emit("refreshTable");
          this.sendStatus = true;

          swal.fire({
            title: "Approved",
            text: msg,
            type: "success",
          });
        } catch (error) {
          console.error(error.message);
          throw new Error(error);
        }
      }
    },
    async decline() {
      const params = {
        type: 2,
        client_account_id: this.client_account_id,
        result_id: this.result,
        status: 3,
        user_id: this.currentUser.id,
        observation: "",
      };

      const { value } = await swal.fire({
        title: "Confirm",
        text: "If are you sure than you want decline this result, insert a observation.",
        showCancelButton: true,
        input: "text",
        confirmButtonText: "Yes, approve",
        type: "question",
        preConfirm: (obs) => {
          if (!obs.length) {
            Swal.showValidationMessage("Has not observation");
          } else {
            params.observation = obs;
          }
          return;
        },
      });
      if (value) {
        try {
          const msg = await DebtorClientsService.registerResultClientAccount(
            params
          );
          // this.$emit("refreshTable");
          this.sendStatus = true;
          swal.fire({
            // title: 'Success!',
            text: msg,
            type: "success",
          });
        } catch (error) {
          console.error(error.message);
          throw new Error(error);
        }
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["currentUser"]),
    hasOptionSelected() {
      return !!this.selected;
    },
    hasSameOptionSelected() {
      return this.result === this.result_id || this.result === null;
    },
    hasStatus() {
      return !!this.status;
    },
    hasNotResult() {
      return this.status == null;
    },
    isPending() {
      return this.status == 1;
    },
    isSupervisorOrCeoOrChief() {
      return (
        this.currentUser.role_id == 1 ||
        this.currentUser.role_id == 2 ||
        this.currentUser.role_id == 17
      );
    },
    isAdvisor() {
      return this.currentUser.role_id == 3;
    },
    isAssistantOrAgent() {
      if (this.currentUser.role_id == 14 || this.currentUser.role_id == 15) {
        return true;
      }
    },
    notHasTransactionId() {
      return this.transaction_id == null;
    },
    moduleId() {
      return this.$route.meta.module;
    },
    dataResultId() {
      return this.result_id;
    },
  },
  watch: {
    hasSameOptionSelected(newVal, oldVal) {
      if (newVal) {
        this.$emit("hasSameOptionSelected", newVal);
      } else {
        this.$emit("hasSameOptionSelected", newVal);
      }
    },
  },
};
</script>